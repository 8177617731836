@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;700&&family=Material+Icons&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;700&display=swap'); */

:root {
  --primary: #0098e3;
  --secondary: #21222a;
  --success: #46d35a;
  --warning: #ffb626;
  --danger: #ed0d32;
  --primaryLight: rgba(0, 153, 230, 0.05);
  --secondaryLight: rgba(33, 34, 42, 0.05);
  --successLight: rgba(69, 211, 90, 0.05);
  --warningLight: rgba(255, 182, 36, 0.05);
  --dangerLight: rgba(237, 12, 50, 0.05);
  --bodyBg: #f0f2f5;
}

body {
  background: #f0f2f5;
  background: var(--bodyBg);
  font-family: 'Exo 2', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.bg-primary {
  background: #0098e3 !important;
  background: var(--primary) !important;
}

.bg-secondary {
  background: #21222a !important;
  background: var(--secondary) !important;
}

.bg-success {
  background: #46d35a !important;
  background: var(--success) !important;
}

.bg-warning {
  background: #ffb626 !important;
  background: var(--warning) !important;
}

.bg-danger {
  background: #ed0d32 !important;
  background: var(--danger) !important;
}

.bg-primary-light {
  background: rgba(0, 153, 230, 0.05) !important;
  background: var(--primaryLight) !important;
}

.bg-secondary-light {
  background: rgba(33, 34, 42, 0.05) !important;
  background: var(--secondaryLight) !important;
}

.bg-success-light {
  background: rgba(69, 211, 90, 0.05) !important;
  background: var(--successLight) !important;
}

.bg-warning-light {
  background: rgba(255, 182, 36, 0.05) !important;
  background: var(--warningLight) !important;
}

.bg-danger-light {
  background: rgba(237, 12, 50, 0.05) !important;
  background: var(--dangerLight) !important;
}

.bg-body {
  background: #f0f2f5;
  background: var(--bodyBg);
}

.container {
  max-width: 1220px;
}

.material-icons {
  font-size: inherit;
  line-height: inherit;
}

.btn-rounded {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1.5rem;
}

.dropdown-menu {
  min-width: 12rem;
}

.modal-content {
  border: none;
  border-radius: 3px;
}

.modal-header,
.modal-footer {
  border: none;
  padding: 30px;
}

.modal-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.modal-body {
  padding: 30px;
}

label {
  /* font-weight: bold; */
}

label .form-text {
  display: inline-block;
  margin-left: 8px;
}

.btn {
  padding: 0.5rem 1.75rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.input-group-text {
  background: none;
}

.form-control-lg,
.input-group-text {
  border-radius: 3px;
}

.form-control[readonly] {
  background: none;
  opacity: 1;
}

.form-control[readonly]:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.alert {
  padding: 1.5rem;
  border-radius: 3px;
}

.btn-back-to {
  margin-bottom: 24px;
}

.btn .material-icons {
  line-height: 100%;
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.data-group {
  margin-bottom: 1.25rem;
}

.data-group p {
  margin: 0;
  margin-bottom: 3px;
}

.data-group h1,
.data-group h2,
.data-group h3,
.data-group h4,
.data-group h5,
.data-group h6 {
  margin: 0;
}

/*====App Structures===*/
.app-container {
}

.app-sidebar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  position: relative;
  z-index: 2;
  max-width: 300px !important;
  min-width: 300px !important;
  min-height: calc(100vh - 65px);
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 12px 12px;
  transition-duration: 0.15s;
  transition-property: background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.app-body {
  display: -webkit-flex;
  display: flex;
  width: 100% !important;
}

.app-page-container {
  width: 100%;
  padding: 15px;
  max-height: calc(100vh - 65px);
  overflow: hidden;
  overflow-y: scroll;
}

/*====Sidebar===*/
.sidebar-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-header-logo {
  text-align: center;
  padding: 20px;
}

.sidebar-header-logo-image {
  max-height: 30px;
  margin-right: 8px;
  float: left;
}

.sidebar-header-logo-text {
  margin: 0;
  margin-top: 2px;
  font-size: 22px;
  float: left;
}

.sidebar-header-logo:after {
  content: '';
  display: table;
  clear: both;
}

.sidebar-button-container {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-body {
}

.sidebar-nav-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 0;
  overflow: hidden;
  overflow-y: auto;
  max-height: 240px;
}

.sidebar-nav-container:hover,
.sidebar-nav-container:focus {
}

.slidebar-nav-list {
  padding: 0;
  margin: 0;
}

.slidebar-nav-list li {
  list-style: none;
  padding-right: 20px;
}

.slidebar-nav-list li a {
  padding: 12px 20px;
  display: block;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.slidebar-nav-list li a:hover,
.slidebar-nav-list li a:focus {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0.02);
}

.slidebar-nav-list li a span.material-icons {
  font-size: 18px;
  margin-right: 6px;
  float: left;
}

.slidebar-nav-list li a:after {
  content: '';
  display: table;
  clear: both;
}

.slidebar-nav-list li.active a {
  color: #0098e3;
  color: var(--primary);
  background: rgba(0, 153, 230, 0.05);
  background: var(--primaryLight);
}
.sidebar-footer {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
  background: #fff;
}

.sidebar-copyright-info {
  padding: 10px 20px;
}

.sidebar-copyright-info p {
  margin: 0;
  text-align: center;
  font-size: 70%;
}

/*===================*/
.app-header {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 12px;
  position: relative;
  z-index: 1;
}
.header-logo {
  height: 30px;
}
.navbar-brand {
  font-weight: 700;
  padding: 0;
}

.navbar-brand .logo-text {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    padding: 1rem 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.navbar .nav-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
}

.navbar .nav-link span.material-icons,
.dropdown-item span.material-icons {
  float: left;
  font-size: 16px;
  margin-right: 6px;
  line-height: inherit;
}

.navbar .navbar-nav > .nav-link:after,
.navbar .navbar-nav > .dropdown .nav-link div:after {
  content: '';
  display: table;
  clear: both;
}

.app-header .navbar .navbar-nav .nav-link.active,
.app-header .navbar .navbar-nav .nav-item.active {
  color: #0098e3;
  color: var(--primary);
  background: rgba(0, 153, 230, 0.05);
  background: var(--primaryLight);
  border-radius: 40px;
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 12px;
  left: auto;
  right: 0;
  padding: 0;
  padding-top: 1.2rem;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
}

/*===Welcome Card===*/
.welcome-card {
  padding: 30px;
  background: #0098e3;
  background: var(--primary);
  color: #fff;
  border-radius: 3px;
  margin-top: 15px;
}

/*======Current Delivery Orders=====*/
.current-delivery-orders-wrapper {
  /* margin-top: 15px; */
}

.current-orders-list-wrapper {
  margin-top: 15px;
  margin-bottom: 15px;
}

.current-orders-filter-list {
  margin: 0;
  padding: 0;
}

.current-orders-filter-list li {
  float: left;
  list-style: none;
  padding: 0.3rem 1.8rem;
  border-radius: 15px;
  margin-left: 5px;
  font-weight: bold;
  color: var(--gray);
}

.current-orders-filter-list li:first-child {
  margin-left: 0;
}

.current-orders-filter-list li.active {
  background: #0098e3;
  background: var(--primary);
  color: #fff;
}

.current-orders-filter-list li:hover,
.current-orders-filter-list li:focus {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
}

.current-orders-filter-list li.active:hover,
.current-orders-filter-list li.active:focus {
  background: #0098e3;
  background: var(--primary);
  color: #fff;
}

.current-orders-filter-list:after {
  content: '';
  display: table;
  clear: both;
}
.current-orders-list-body {
  background: #fff;
  border-radius: 3px;
  margin-top: 20px;
  min-height: calc(640px - 40.6px);
  position: relative;
}

.orders-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.orders-column-header {
  margin: 0;
  padding: 20px 20px;
}

.orders-body {
}

.order-item-detail-list {
  padding: 0;
  margin: 0;
}

.order-item-detail-list li {
  list-style: none;
  font-size: 0.8125rem;
  line-height: 1.6;
}

.order-item-detail-list li strong {
  font-size: 0.875rem;
}

.order-item {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.order-item:hover,
.order-item:focus {
  background: rgba(0, 0, 0, 0.03);
}

.order-item .dropdown-menu {
  padding-top: 0;
}

.order-item-first {
  border-top: 0;
}

.column-group,
.action-group {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.column-group {
}

.column-group:hover {
  background: rgba(0, 0, 0, 0.02);
}

.action-group {
  text-align: right;
}

/*-Info Box-*/
.info-box {
}

.info-box-full {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
}

.info-box-inner {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.info-box-content {
  max-width: 380px;
  text-align: center;
}

.info-box-icon-wrapper {
  display: inline-block;
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  background: rgba(0, 153, 230, 0.05);
  background: var(--primaryLight);
  margin-bottom: 15px;
}

.info-box-icon-wrapper span.material-icons {
  font-size: 28px;
  color: #0098e3;
  color: var(--primary);
}

.info-box-content h6 {
}

.info-box-content p {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

/*-Map-*/

.current-orders-map {
  margin-top: 15px;
  padding: 10px;
  border-radius: 3px;
  background: #fff;
}

.current-orders-map .leaflet-container {
  height: 500px;
}

/*====Icon Nav=====*/
.icon-nav-list {
  padding: 0;
  margin: 0;
}

.icon-nav-list li {
  list-style: none;
  text-align: center;
  margin-top: 10px;
  background: #fff;
  border-radius: 3px;
  padding: 30px 10px;
  opacity: 0.5;
}

.icon-nav-list li:hover,
.icon-nav-list li:focus {
  cursor: pointer;
  opacity: 1;
}

.icon-nav-list li:first-child {
  margin: 0;
}

.icon-nav-list li .icon-nav-icon {
  font-size: 24px;
  line-height: 100%;
}

.icon-nav-list li .icon-nav-label {
  display: block;
  line-height: 100%;
  font-weight: bold;
}

.icon-nav-list li.active {
  background: #0098e3;
  background: var(--primary);
  color: #fff;
  opacity: 1;
  position: relative;
}

.icon-nav-list li.active:before {
  content: '';
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #0098e3;
  border-left: 10px solid var(--primary);
  position: absolute;
  right: -9px;
  top: calc(50% - 10px);
}

.icon-nav-list li.active .icon-nav-icon {
}

.icon-nav-list li.active .icon-nav-label {
}

/*=====Order Form====*/
.order-form-wrapper {
  padding: 30px;
  background: #fff;
  border-radius: 3px;
  min-height: 440px;
}

.store-info-wrapper {
  padding: 30px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 3px;
}

.organization-logo,
.organization-info {
  float: left;
}

.organization-logo {
  margin-right: 15px;
  height: 90px;
  width: 90px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.organization-logo img {
  max-width: 100%;
}

.no-business-logo {
  text-align: center;
  line-height: 80px;
  margin: 0;
}

.store-info-wrapper h6 {
}

.store-info-contact-list {
  margin: 0;
  padding: 0;
}

.store-info-contact-list li {
  list-style: none;
}

.store-info-wrapper:after {
  content: '';
  display: table;
  clear: both;
}

.css-yk16xz-control,
.css-yk16xz-control:hover {
  border-color: #ced4da !important;
}

.css-yk16xz-control {
  border-radius: 3px;
}

.css-yk16xz-control:hover {
  cursor: pointer;
}

.css-2b097c-container {
  z-index: 999;
}

.react-select-lg .css-g1d714-ValueContainer {
  padding: 8px 16px;
}

.react-select-lg .css-1uccc91-singleValue {
  font-size: 1.25rem;
  color: #495057;
}

.location-type-nav {
  margin: 0 0 20px 0;
  padding: 0;
}

.location-type-nav li {
  list-style: none;
  float: left;
  margin-left: 15px;
  padding: 6px 15px;
  border-radius: 21px;
  background: rgba(0, 0, 0, 0.03);
}

.location-type-nav li:hover,
.location-type-nav li:focus {
  cursor: pointer;
}

.location-type-nav li:first-child {
  margin-left: 0;
}

.location-type-nav li.active {
  background: #0098e3;
  background: var(--primary);
  color: #fff;
}

.location-type-nav::after {
  content: '';
  display: table;
  clear: both;
}

.delivery-address-map-wrapper {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.delivery-address-map-wrapper .leaflet-container {
  height: 600px;
}

.search-address-input-wrapper {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  z-index: 9999;
}

.search-address-input {
  padding: 5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.search-address-input .form-control {
  border: 0;
}

.search-address-input .form-control:hover,
.search-address-input .form-control:focus {
  box-shadow: none;
}

.search-address-prediction-wrapper {
  margin-top: 5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.prediction-list {
  margin: 0;
  padding: 0;
}

.prediction-list li {
  list-style: none;
  position: relative;
  padding: 16px 16px 16px 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
}

.prediction-list li:hover,
.prediction-list li:focus {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03);
}

.prediction-list li span.material-icons {
  position: absolute;
  left: 16px;
  top: 18px;
  font-size: 20px;
  line-height: 100%;
}

.prediction-list li h6 {
  margin: 0;
}

.prediction-list li p {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.recipient-address-wrapper {
  position: absolute;
  z-index: 9999;
  left: 10px;
  right: 10px;
  bottom: 10px;
}

.recipient-address-inner {
  position: relative;
  padding: 22px 22px 22px 56px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.recipient-address-inner span.material-icons {
  position: absolute;
  left: 16px;
  top: 24px;
  font-size: 24px;
  line-height: 100%;
}

.recipient-address-inner h5 {
  margin: 0;
}

.recipient-address-inner p {
  margin: 0;
}

/*-Delivery Slot-*/
.slot-list {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.slot-list li {
  list-style: none;
  margin-top: 15px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.slot-list li:hover,
.slot-list li:focus {
  cursor: pointer;
}

.slot-list li:first-child {
  margin-top: 0;
}

.slot-list li.active {
  background: #0098e3;
  background: var(--primary);
  border-color: #0098e3;
  border-color: var(--primary);
  color: #fff;
}

/*-Delivery Summary-*/
.delivery-summary-card {
  padding: 30px;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}

.eq-dsc {
  min-height: 200px;
}

.delivery-summary-list {
  margin: 0;
  padding: 0;
}

.delivery-summary-list li {
  list-style: none;
}

.dc-processing-indicator {
  padding: 30px;
  background: #fff;
  border-radius: 3px;
}

/*-Delivery Feature-*/
.delivery-feature-wrapper {
  background: #fff;
  border-radius: 3px;
  padding: 20px;
}

.delivery-feature-list {
  margin: 0;
  padding: 0;
}

.delivery-feature-list li {
  list-style: none;
  margin-top: 20px;
}

.delivery-feature-list li:first-child {
  margin-top: 0;
}

.delivery-feature-list li h6 {
  margin: 0;
}

.delivery-feature-list li p {
  margin: 0;
}

/*====Login Page====*/

.login-outer-wrapper,
.signup-outer-wrapper,
.slv-outer-wrapper {
  height: 100%;
  min-height: 100vh;
}

.login-container {
  padding: 0 15px;
  max-width: 520px;
  margin: 0 auto;
}

.signup-container {
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
}

.slv-container {
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
}

.login-inner-wrapper,
.signup-inner-wrapper,
.slv-inner-wrapper {
  padding: 100px 0 50px 0;
}

.login-inner-wrapper .form-group,
.signup-inner-wrapper .form-group,
.slv-inner-wrapper .form-group {
  margin-bottom: 1.5rem;
}

.login-header-wrapper,
.signup-header-wrapper,
.slv-header-wrapper {
  text-align: center;
  color: #fff;
}

.login-form-wrapper,
.signup-form-wrapper,
.slv-form-wrapper {
  margin-top: 30px;
  padding: 40px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 15px;
}

.store-address-wrapper,
.warehouse-address-wrapper {
  border: 1px solid #ced4da;
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 400;
  color: #495057;
  background: #fff;
}

.store-address-wrapper:hover,
.store-address-wrapper:focus,
.warehouse-address-wrapper:hover,
.warehouse-address-wrapper:focus {
  cursor: pointer;
}

.set-address-wrapper {
  font-size: 14px;
  line-height: 1.8rem;
}

.set-address-wrapper .material-icons {
  display: inline-block;
  line-height: 100%;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.np-flag-wrapper .np-flag {
  width: auto;
  height: 34px;
}

.store-location-map-container .leaflet-container,
.warehouse-location-map-container .leaflet-container {
  height: 600px;
}

.signup-response-message-wrapper,
.slv-response-message-wrapper {
  margin-top: 30px;
  padding: 40px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 15px;
}

/* ==Estimate Delivery== */
.estimate-form-wrapper {
  background: #fff;
  padding: 30px;
  border-radius: 3px;
}

/*===Leaflet Map===*/
.leaflet-container {
  height: 400px;
  font: inherit;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.leaflet-popup-content-wrapper {
  border-radius: 3px;
}

/* =========Profile========= */
.app-center-container {
  padding: 50px 0;
}

.profile-card {
  padding: 40px;
  background: #fff;
  border-radius: 3px;
}

.profile-nav-list {
  margin: 0;
  padding: 0;
  position: relative;
  padding-left: 15px;
}

.profile-nav-list:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.profile-nav-list li {
  list-style: none;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  margin-top: 10px;
}

.profile-nav-list li:first-child {
  margin: 0;
}

.profile-nav-list li .material-icons {
  font-size: 18px;
  line-height: 100%;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.profile-nav-list li:hover,
.profile-nav-list li:focus {
  cursor: pointer;
  color: #212529;
}

.profile-nav-list li.active {
  color: #212529;
}

.profile-nav-list li.active:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #212529;
  border-radius: 3px;
}

/* =======Delivery History========== */
.delivery-history-wrapper {
  background: #fff;
  border-radius: 3px;
  margin-top: 20px;
  min-height: calc(640px - 40.6px);
  position: relative;
}

.delivery-item-details-wrapper {
  background: #fff;
  border-radius: 3px;
  margin-top: 20px;
  min-height: calc(640px - 40.6px);
  max-height: calc(1200px - 40.6px);
  overflow-y: scroll;
}

.history-order-item:hover,
.history-order-item:focus {
  cursor: pointer;
}

.delivery-item-details-group {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.delivery-item-details-header {
  padding: 20px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.delivery-item-details-close {
  display: inline-block;
  padding: 0 5px;
}

.delivery-item-details-close .material-icons {
  line-height: 1;
}

.delivery-item-details-close:hover,
.delivery-item-details-close:focus {
  cursor: pointer;
  color: #000;
}

.pagination-wrapper {
  margin-top: 1rem;
  background: #fff;
  padding: 15px;
  border-radius: 3px;
}

.pagination-wrapper .pagination {
  margin: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-link {
  color: #212529;
}

.page-link:hover {
  color: #000;
}

.route-container {
  padding: 30px;
  background: #fff;
  border-radius: 3px;
}

.route-container .leaflet-container {
  min-height: calc(100vh - 200px);
}

.custom-cluster-marker {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
}

.leaflet-marker-icon {
  background: transparent;
  border: 0;
}

.marker-div-wrapper {
  height: 42px;
  width: 42px;
  text-align: center;
  line-height: 32px;
  margin-left: -21px;
  margin-top: -42px;
  position: relative;
}

.marker-div-wrapper:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  position: absolute;
  right: calc(50% - 4px);
  top: 14px;
}

.marker-div-wrapper svg {
  width: auto;
  height: 42px;
}

.route-position {
  position: absolute;
  background: white;
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  font-weight: bold;
  border-radius: 50%;
  margin-left: -2px;
  margin-top: -2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.form-wrapper {
  padding: 30px;
  background: #fff;
  border-radius: 3px;
}

.mo-bordered-list {
  margin: 0;
  padding: 0;
}

.mo-bordered-list li {
  list-style: none;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  margin-top: 20px;
}

.mo-bordered-list li:hover,
.mo-bordered-list li:focus {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.02);
}

.mo-bordered-list li:first-child {
  margin-top: 0;
}

.mo-bordered-list li h6 {
}

.mo-bordered-list li p {
  margin-bottom: 0;
}

.mo-so-eq {
  min-height: 500px;
  max-height: 500px;
  padding-right: 5px;
  overflow-y: scroll;
}

.modal-90w {
  max-width: 90%;
}

.route-info-list {
  margin: 0;
  padding: 0;
}

.route-info-list li {
  list-style: none;
  padding: 10px;
  padding-left: 48px;
  border-radius: 3px;
  position: relative;
  color: #fff;
  margin-top: 20px;
}

.route-info-list li .css-2b097c-container {
  z-index: unset;
}

.route-info-list li:first-child {
  margin-top: 0;
}

.route-info-list li .material-icons {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 24px;
  line-height: 100%;
}

.route-info-list li h6 {
}

.route-info-list li p {
  margin: 0;
}

.css-26l3qy-menu {
  z-index: 999999;
}

.page-link:hover,
.page-link:focus {
  cursor: pointer;
}

.large-map .leaflet-container {
  height: 800px;
}

